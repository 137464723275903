<template>
  <v-dialog
    v-model="show"
    persistent
    content-class="elevation-0"
    :width="$vuetify.breakpoint.xsOnly ? null : 500"
  >
    <v-container>
      <v-row dense>
        <v-col cols="11">
          <v-card class="pa-8 rounded-lg">
            <v-card-title class="pa-0">
              <span class="lato-light f30 break-word">
                Congratulations!
              </span>
            </v-card-title>

            <v-card-text class="px-0 py-6 pb-2">
              <span class="black1 f15">
                Your account is now active. You can view your orders history, track your eGift transactions status, add contacts, load CG Credits for fast checkout & much more.
              </span>
            </v-card-text>

            <v-card-actions class="pa-0 mt-4">
              <common-button
                class="rounded-xl"
                height="44"
                width="130"
                @click="show = false"
              >
                Got it!
              </common-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="1" class="text-center">
          <v-icon
            color="white"
            x-large
            @click="show = false"
            class="pointer"
          >
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'WelcomeModal',
  data: () => ({
    show: false
  }),
  methods: {
    showModal () {
      const url = window.location.pathname;
      const params = new URLSearchParams(window.location.search);

      if ((url.includes('/dashboard') || url.includes('my-campaigns/create/review')) && params.get('activation') === 'true') {
        this.show = true
      }
    }
  },
  mounted () {
    this.showModal()
  }
}
</script>
